<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div class="input-group">
          <input
            v-model="value"
            type="text"
            :readonly="field.readonly === undefined || field.readonly"
            class="form-control"
            :class="validationState"
            :placeholder="field.placeholder ? field.placeholder : ''"
          />
        </div>
        <button class="btn btn-primary mt-2" @click="openFileManager()">
          {{ $t("workflowElements.fileAccess.fileManagerOpen") }}
        </button>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  props: {
    field: {
      type: Object
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    node: {
      type: Object,
      default: () => {}
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: ""
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    validationState: function () {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (!this.value) {
        return "is-invalid";
      }
      return "is-valid";
    }
  },
  watch: {
    value: function () {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);
    }
  },
  mounted() {
    this.value = this.field.value ?? "";
    bus.$on("fileAccessTreeSelected", this.onFileAccessTreeSelected);
  },
  destroyed() {
    bus.$off("fileAccessTreeSelected", this.onFileAccessTreeSelected);
  },
  methods: {
    onFileAccessTreeSelected(selected) {
      if (this.field.type === "tenantStorage") {
        this.value = selected.filePath;
        this.$set(this.field, "value", this.value);
      }
    },
    openFileManager() {
      bus.$emit("openWFDFileManager", { nodeId: this.node.attrs.id });
    }
  }
};
</script>
